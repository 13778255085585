"use client";

import React from "react";
import { Icon, Tabs, ToastProvider } from "@figmentjs/web-core";
import { Tab } from "@figmentjs/web-core/src/components/navigation/Tabs/Tabs.types";
import { WalletDetails } from "@figmentjs/staking-components";
import { Stake } from "../stake";
import { Rewards } from "../rewards";

export const Home = () => {
  const [selectedTabId, setSelectedTabId] = React.useState("stake");

  const tabs: Tab[] = React.useMemo(
    () => [
      {
        id: "stake",
        title: "Stake",
        selected: selectedTabId === "stake",
      },

      {
        id: "rewards",
        title: "Rewards",
        selected: selectedTabId === "rewards",
      },
    ],
    [selectedTabId]
  );

  return (
    <main className="flex min-h-screen flex-col items-center justify-between py-3 sm:p-4 bg-white w-full">
      <ToastProvider placement="BOTTOM_RIGHT">
        <div className="w-full sm:w-11/12">
          <div className="flex flex-col justify-between">
            <Tabs
              tabs={tabs}
              placement="start"
              onTabChange={setSelectedTabId}
              beforeTabs={
                <div className="flex items-center gap-4 mr-auto px-4 sm:px-0 sm:mr-8">
                  <div className="hidden md:block">
                    <Icon icon="FigmentLogoIcon" size="4xl" />
                  </div>
                  <div className="block pb-2 md:pb-0 md:hidden">
                    <Icon icon="FigmentLogoIcon" size="3xl" />
                  </div>
                  <Icon icon="FigmentLogoText" color="basic-1000" />
                </div>
              }
              afterTabs={
                <>
                  <div className="hidden sm:block sm:ml-auto">
                    <WalletDetails />
                  </div>
                  <div className="pr-3 sm:pr-0" />
                </>
              }
            >
              <div className="m-auto max-w-5xl">
                <Tabs.Content tabId="stake">
                  <div className="block border-b border-b-basic-200 py-3 sm:p-0 sm:border-none sm:hidden">
                    <div className="px-4 sm:px-0">
                      <WalletDetails />
                    </div>
                  </div>
                  <div className="px-4 sm:px-0">
                    <Stake />
                  </div>
                </Tabs.Content>
                <Tabs.Content tabId="rewards">
                  <div className="px-4 sm:px-0">
                    <Rewards setSelectedTabId={setSelectedTabId} />
                  </div>
                </Tabs.Content>
              </div>
            </Tabs>
          </div>
        </div>
      </ToastProvider>
    </main>
  );
};
