import React, { useCallback } from "react";
import { UnstakingFlowData } from "@figmentjs/staking-components";

export const initialValues: UnstakingFlowData = {
  amount: 32,
  address: "",
  initiateUnstaking: false,
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<UnstakingFlowData>) => void;

export const useFlowData = (initialFlowData = initialValues) => {
  const [flowData, setFlowData] =
    React.useState<UnstakingFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
