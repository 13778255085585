import React, { useEffect, useState } from "react";
import {
  DAppActionEnum as Actions,
  DAppObjectsEnum,
  DAppTrackEventProperties,
} from "@figmentjs/analytics/apps";
import { useSegment } from "@app/app/hooks";

import {
  AmountStepUnstake,
  VerifyOwnershipStepUnstake,
  SuccessStepUnstake,
  UnstakingFlowStep,
  ErrorStep,
} from "@figmentjs/staking-components";
import { useFlowData, initialValues } from "./hooks";
import Providers from "@figmentjs/web-core/src/components/providers";
import tailwindConfig from "tailwind.config";

const smallScreenWidth = parseInt(tailwindConfig.theme.screens.sm);

export const UnstakingFlow: React.FC = () => {
  const [step, setStep] = useState<UnstakingFlowStep>(UnstakingFlowStep.AMOUNT);
  const { flowData, updateFlowData } = useFlowData();
  const { trackEvent } = useSegment();
  const { network } = Providers.useEthereumWallet();

  const trackStakingFlowEvent = (
    action: Actions,
    properties: DAppTrackEventProperties
  ) => {
    trackEvent(
      { object: DAppObjectsEnum.UNSTAKING_FLOW, action },
      {
        network,
        amountToUnstake: flowData.amount,
        isMobile: window.innerWidth < smallScreenWidth,
        ...properties,
      }
    );
  };

  const reset = () => {
    updateFlowData(initialValues);
    setStep(UnstakingFlowStep.AMOUNT);
  };

  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div className="mt-6">
      {((): React.ReactNode => {
        switch (step) {
          case UnstakingFlowStep.AMOUNT:
            return (
              <AmountStepUnstake
                onContinue={(values) => {
                  trackStakingFlowEvent(Actions.CONTINUE_BTN_CLICKED, { step });
                  updateFlowData(values);
                }}
                onSuccess={() => {
                  trackStakingFlowEvent(Actions.CONTINUE_BTN_CLICKED, { step });
                  setStep(UnstakingFlowStep.SUCCESS);
                }}
                onAccessTokenInvalid={() => {
                  setStep(UnstakingFlowStep.VERIFY_OWNERSHIP);
                }}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(UnstakingFlowStep.ERROR);
                }}
                flowData={flowData}
              />
            );
          case UnstakingFlowStep.VERIFY_OWNERSHIP:
            return (
              <VerifyOwnershipStepUnstake
                onBack={() => {
                  trackStakingFlowEvent(Actions.BACK_BTN_CLICKED, { step });
                  setStep(UnstakingFlowStep.AMOUNT);
                }}
                onSuccess={(values) => {
                  trackStakingFlowEvent(Actions.CONTINUE_BTN_CLICKED, { step });
                  updateFlowData(values);
                  setStep(UnstakingFlowStep.SUCCESS);
                }}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(UnstakingFlowStep.ERROR);
                }}
                flowData={flowData}
              />
            );
          case UnstakingFlowStep.ERROR:
            return (
              <ErrorStep
                onTryAgain={() => {
                  trackStakingFlowEvent(Actions.TRY_AGAIN_BTN_CLICKED, {
                    step,
                  });
                  reset();
                }}
                flowData={flowData}
              />
            );
          case UnstakingFlowStep.SUCCESS:
            return (
              <SuccessStepUnstake
                onDone={() => {
                  trackStakingFlowEvent(Actions.DONE_BUTTON_CLICKED, { step });
                  reset();
                }}
                flowData={flowData}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
