import React from "react";
import { Resources, ActivityTable } from "@figmentjs/staking-components";
import { StakingFlow, ValueProp } from "./components";
import {
  Tab,
  TabSizes,
  TabVariants,
} from "@figmentjs/web-core/src/components/navigation/Tabs/Tabs.types";
import { Tabs } from "@figmentjs/web-core";
import { UnstakingFlow } from "./components/unstaking-flow";
import { useExitableValidatorCountQuery } from "@figmentjs/staking-components/graphql/partners/generated/gql";
import Providers from "@figmentjs/web-core/src/components/providers";

export const Stake: React.FC = () => {
  const { account, network } = Providers.useEthereumWallet();

  const {
    data: exitableValidators,
    isLoading: isExitableValidatorsLoading,
    error,
  } = useExitableValidatorCountQuery(
    {
      ethAccount: account!,
      network: network!,
    },
    {
      enabled: !!(account && network),
    }
  );

  const isUnstakeDisabled =
    isExitableValidatorsLoading ||
    error ||
    exitableValidators?.validatorCounts.exitable === 0;

  const tabs: Tab[] = [
    {
      id: "stake",
      title: "Stake",
    },
    {
      id: "unstake",
      title: "Unstake",
      disabled: !!isUnstakeDisabled,
      tooltip: isUnstakeDisabled
        ? "Unstaking will be possible once your validator(s) are exitable."
        : "",
    },
  ];

  return (
    <div className="mt-4 sm:mt-8">
      <div className="flex flex-col sm:flex-row sm:gap-20">
        <ValueProp />
        <div className="flex-1 border border-basic-600 rounded-2xl pt-4 p-8">
          <Tabs
            tabs={tabs}
            placement="justify-evenly"
            variant={TabVariants.secondary}
            size={TabSizes.md}
          >
            <Tabs.Content tabId="stake">
              <StakingFlow />
            </Tabs.Content>
            <Tabs.Content tabId="unstake">
              <UnstakingFlow />
            </Tabs.Content>
          </Tabs>
        </div>
      </div>
      <div className="flex flex-col">
        <ActivityTable />
        <div className="mt-10">
          <Resources />
        </div>
      </div>
    </div>
  );
};
