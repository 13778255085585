import React from "react";
import { Rewards as RewardsTab } from "@figmentjs/staking-components";

type Props = {
  setSelectedTabId: React.Dispatch<React.SetStateAction<string>>;
};

export const Rewards: React.FC<Props> = ({ setSelectedTabId }) => {
  return <RewardsTab setSelectedTabId={setSelectedTabId} />;
};
